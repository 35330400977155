<div class="backdrop">

  <div class="login-form">
    <h1>
      project<span>red</span>
    </h1>
    <h2>creating our future</h2>
    <img class="login-btn" src="assets/img/siwg.png" alt="Sign in with Google" *ngIf="!showThrobber">
    <img class="login-throbber" src="assets/img/throbber.svg" alt="Signing in..." *ngIf="showThrobber">
    <p class="login-message small" *ngIf="showError">Authentication Failed</p>
  </div>

</div>
