import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  public showThrobber = true;
  public showError = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (typeof this.route.snapshot.params.token === 'undefined') {
      this.showThrobber = false;
    } else if (this.route.snapshot.params.token === 'failed') {
      this.showThrobber = false;
      this.showError = true;
    }
  }

}
